// TODO: Resolver esse componente
export const LoadingPlugin = {
  install (Vue) {
    Vue.prototype.$loading = {
      show: function () {
        this.showCallback();
      },
      hide: function () {
        this.hideCallback();
      },
      _onShow: function (callback) {
        this.showCallback = callback;
      },
      _onHide: function (callback) {
        this.hideCallback = callback;
      },
    };
  },
};
