import Vue from 'vue';

import '@estrategiahq/coruja-web-ui/dist/coruja-tailwind.css';
import '@estrategiahq/coruja-web-ui/dist/coruja-web-ui.css';
import '@/assets/styles/main.css';

import App from './App.vue';
import { router } from './routing';

import { BridgerPlugin, establishBridgerConnection } from './plugins/bridger';
import { EnvPlugin } from '@/plugins/env';
import { ModalPlugin } from '@/plugins/modal';
import { ToastPlugin } from '@/plugins/toast';
import { ServicesPlugin } from '@/plugins/services';
import { BusPlugin } from '@/plugins/bus';
import { LoadingPlugin } from '@/plugins/loading';
import { DialogPlugin } from './plugins/dialog';

Vue.config.productionTip = false;

async function init() {
  await establishBridgerConnection();

  Vue.use(BridgerPlugin, router);
  Vue.use(EnvPlugin);
  Vue.use(BusPlugin);
  Vue.use(LoadingPlugin);
  Vue.use(ModalPlugin);
  Vue.use(ToastPlugin);
  Vue.use(ServicesPlugin);
  Vue.use(DialogPlugin);

  new Vue({
    router,
    render: h => h(App),
  }).$mount('#app');
}

init();
