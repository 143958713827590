export const DialogPlugin = {
  install: (Vue) => {
    Vue.prototype.$dialog = {
      onResolve: () => null,
      /**
       * @param { Object } data
       * @param { number } [data.overlayOpacity]
       * @param { string } [data.title]
       * @param { string } [data.message]
       * @param { string } [data.cancelButtonName]
       * @param { string } [data.confirButtonName]
       */
      show: function (data) {
        if (!data.overlayOpacity) data.overlayOpacity = 0.25;
        if (!data.title) throw new Error('"title" is required');

        this.showCallback(data);

        return new Promise(resolve => {
          this.onResolve = resolve;
        });
      },
      hide: function () { this.hideCallback() },
      _onShow: function (callback) { this.showCallback = callback },
      _onHide: function (callback) { this.hideCallback = callback },
    };
  },
};
