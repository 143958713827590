export class CatalogsService {
  /**
   * @param {AxiosInstance} httpClient
   */
  constructor (httpClient) {
    this.$http = httpClient;
  }

  getById (vertical, id, params = {}) {
    const config = {
      headers: {
        'X-Vertical': vertical,
      },
      params: {
        per_page: params.perPage,
        page: params.currentPage,
        name: params.search,
      },
    };

    return this.$http.get(`/categories/${id}`, config).then(resp => {
      const { data, meta } = resp.data;

      return {
        catalog: data,
        total: meta.total,
      };
    });

  }

  getAll (vertical, params = {}) {
    const config = {
      headers: {
        'X-Vertical': vertical,
      },
      params: {
        per_page: params.perPage,
        page: params.currentPage,
        name: params.search,
      },
    };

    return this.$http.get('/categories', config).then(resp => {
      const { data, meta } = resp.data;

      return {
        catalogs: data,
        total: meta.total,
      };
    });
  }
}
