<template>
  <div
    v-if="!!modalData"
    id="modal-component-overlay"
    class="ui-fixed ui-top-0 ui-left-0 ui-z-50 ui-flex ui-items-center ui-justify-center ui-w-full ui-h-full"
    :style="`background: rgba(0, 0, 0, ${modalData.overlayOpacity})`"
  >
    <div class="ui-fixed ui-h-full ui-w-full">
      <component
        :is="modalData.component"
        v-bind="modalData.props"
      />
    </div>
  </div>
</template>

<script>

export default {
  name: 'CModal',
  data: () => ({
    modalData: null,
  }),
  mounted() {
    this.$modal._onShow(data => {
      document.getElementById('app').classList.add('ui-overflow-hidden');
      this.modalData = data;
    });
    this.$modal._onHide(() => {
      document.getElementById('app').classList.remove('ui-overflow-hidden');
      this.modalData = null;
    });
  },
};
</script>
