export const events = {
  LOADING_START: 'loading_start',
  LOADING_STOP: 'loading_stop',
  REQUEST_ERROR: 'request_error',
};

export const TOKEN = 'token';

export const requiredPermissionsForUsersGroupsPage = [
  'accounts.groups.list',
  'accounts.groups.create',
  'accounts.groups.get',
  'accounts.groups.update',
  'accounts.groups.delete',
  'accounts.permissions.list',
  'accounts.groups_permissions.update',
  'accounts.users.list',
  'accounts.permissions.list',
  'accounts.permissions.get',
  'accounts.permissions.create',
  'accounts.permissions.update',
  'accounts.permissions.delete',
];

export const requiredPermissionsForMainPage = [
  'accounts.user.list',
  'accounts.user.get',
  'accounts.user.create',
  'accounts.user.update',
  'accounts.user.invite-manager',
  'accounts.groups_users.add',
  'accounts.groups_users.remove',
  'accounts.student.list',
  'accounts.student.get',
  'accounts.student.get',
  'accounts.student.update',
  'accounts.student.list',
  'accounts.student.get',
];

export const requiredPermissionForLoginAsUser = 'accounts.user.login_as_other_user';

export const METABASE_URL = 'https://metabase.estrategialabs.com';

export default {
  events,
  TOKEN,
};
