import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: () => import('@/layouts/MainLayout'),
    children: [
      { path: '', redirect: { name: 'users' } },

      { path: 'usuarios', name: 'users', component: () => import('@/pages/Users/List') },
      { path: 'usuarios/:id', name: 'user-detail', component: () => import('@/pages/Users/Detail') },
      { path: 'usuarios/:id', name: 'user-edit', component: () => import('@/pages/Users/Edit') },
      { path: 'usuarios/:id/grupos', name: 'users-groups', component: () => import('@/pages/Users/Groups') },
      { path: 'usuarios/:id/pagamento', name: 'user-payment', component: () => import('@/pages/Users/Payment') },

      { path: 'grupos', name: 'groups', component: () => import('@/pages/Groups/List') },
      { path: 'grupos/:id', name: 'groups-detail', component: () => import('@/pages/Groups/Detail') },
      { path: 'grupos/:id/usuarios', name: 'groups-users', component: () => import('@/pages/Groups/Users') },

      { path: 'permissoes', name: 'permissions', component: () => import('@/pages/Permissions/List') },
    ],
  },
];

export const router = new VueRouter({
  routes,
  mode: 'hash',
  base: process.env.BASE_URL,
});
