import { Auth } from '@estrategiahq/frontend-libs/user-manager';
import axios from 'axios';
import { bus } from '@/plugins/bus';
import { events, TOKEN } from '@/constants';
import { env } from './env';
import { AuthService } from '@/services/authService';
import { GroupService } from '@/services/groupService';
import { UserService } from '@/services/userService';
import { CatalogsService } from '@/services/catalogsService';
import { PermissionService } from '@/services/permissionService';
import { SessionService } from '@/services/sessionService';

const requesterId = 'bo-accounts';

const httpClient = axios.create({ baseURL: env.API_URL, timeout: 50000 });
const httpClientCatalogs = axios.create({
  baseURL: env.CATALOGS_API_URL,
  timeout: 50000,
});

httpClient.defaults.headers.common['Content-Type'] = 'application/json';
httpClient.interceptors.request.use((config) => {
  const token = localStorage.getItem(TOKEN);
  config.headers.Authorization = `Bearer ${token}`;
  config.headers['X-Version'] = 'v2';
  config.headers['X-Requester-id'] = requesterId;
  return config;
});

httpClientCatalogs.defaults.headers.common['Content-Type'] = 'application/json';
httpClientCatalogs.interceptors.request.use((config) => {
  const token = localStorage.getItem(TOKEN);
  config.headers.Authorization = `Bearer ${token}`;
  config.headers['X-Requester-id'] = requesterId;
  return config;
});

const onRequestSuccess = (evt) => (config) => {
  // TODO: Quando possível, remover esse bus
  bus.publish(evt);
  return config;
};

const onResponseSuccess = (evt) => (config) => {
  // TODO: Quando possível, remover esse bus
  bus.publish(evt);

  // Se não tem meta, só retorna o data para não ter que fazer parse
  if (!config.data.meta) {
    return config.data.data;
  }

  return config;
};

const onError = (error) => {
  const apiError = error?.response?.data?.error;
  const e = apiError ? { ...apiError, isApiError: true } : error;

  // TODO: Quando possível, remover esse bus
  bus.publish(events.REQUEST_ERROR);
  return Promise.reject(e);
};

httpClient.interceptors.request.use(onRequestSuccess(events.LOADING_START), onError);
httpClient.interceptors.response.use(onResponseSuccess(events.LOADING_STOP), onError);

// Não habilita loading padrão para o client do catalogs
httpClientCatalogs.interceptors.request.use(null, onError);
httpClientCatalogs.interceptors.response.use(null, onError);

const authLib = new Auth(env.API_URL, requesterId);

export const ServicesPlugin = {
  install: (Vue) => {
    Vue.prototype.$s = {
      permission: new PermissionService(httpClient),
      group: new GroupService(httpClient),
      user: new UserService(httpClient),
      auth: new AuthService(authLib),
      catalogs: new CatalogsService(httpClientCatalogs),
      session: new SessionService(httpClient),
    };
  },
};
