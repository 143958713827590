import axios from 'axios';
import { Bridger } from '@estrategiahq/frontend-libs/bridger';
import { TOKEN } from '@/constants';

const bridger = Bridger().Consumer();

export const establishBridgerConnection = function () {
  return new Promise((resolve, reject) => {
    const onTokenReceived = (payload) => {
      localStorage.setItem(TOKEN, payload.token);
      resolve(bridger);
    };

    bridger.$subscribe('event:user-login', onTokenReceived);

    // Essa parte é feita pelo BO-Container. Estamos replicando aqui para testes locais e independentes
    if (process.env.VUE_APP_LOCAL_EMAIL) {
      // se já tem token não precisa logar de novo
      if (localStorage.getItem(TOKEN)) {
        resolve(bridger);
        return;
      }
      const baseUrl = process.env.VUE_APP_API_URL; // Não está usando o env para não acoplar testes ao sistema de fato
      const email = process.env.VUE_APP_LOCAL_EMAIL;
      const password = process.env.VUE_APP_LOCAL_PASSWORD;
      const url = `${baseUrl}/auth/login`;

      return axios.post(url, { email, password })
        .then(res => res.data.data)
        .then(user => onTokenReceived(user))
        .catch(err => reject(err));
    }
  });
};

export const BridgerPlugin = {
  install (Vue, router) {
    // Isso é necessário para que o redirect com param funcione no BO-Container ($router.push({ name: 'user-detail', params: { id } }))
    router.afterEach((to) => {
      bridger.$dispatch({
        type: 'router_navigation:after',
        payload: {
          fullPath: to.fullPath,
        },
      });
    });
  },
};
