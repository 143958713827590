export class SessionService {
  constructor (httpClient) {
    this.$http = httpClient;
  }

  async getUserSessions(userId) {
    const sessions = await this.$http.get(`/auth/session/user/${userId}`);
    sessions.sort((x, y) => x.created_at < y.created_at ? 1 : -1);
    return sessions;
  }

  invalidateUserSession(userID, sessionID) {
    return this.$http.delete(`/auth/session/user/${userID}/${sessionID}`);
  }
}
