export const env = {
  API_URL: process.env.VUE_APP_API_URL,
  INVITE_USER_TARGET_URL: process.env.VUE_APP_INVITE_USER_TARGET_URL,
  CATALOGS_API_URL: process.env.VUE_APP_CATALOGS_API_URL,
  PERFIL_URL: process.env.VUE_APP_PERFIL_URL,
};

export const EnvPlugin = {
  install (Vue) {
    Vue.prototype.$env = Vue.env = Object.freeze(env);
  },
};
