export const ToastPlugin = {
  install: (Vue) => {
    Vue.prototype.$toast = {
      show: function (props) { this.showCallback(props) },
      hide: function () { this.hideCallback() },
      _onShow: function (callback) { this.showCallback = callback },
      _onHide: function (callback) { this.hideCallback = callback },
    };
  },
};
