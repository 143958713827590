<template>
  <div id="app">
    <theme-provider style="min-height: 100vh;">
      <div class="container-fluid">
        <router-view :logged-user="loggedUser" />
      </div>

      <c-loading />

      <c-modal />

      <c-dialog />

      <c-toast
        id="toast"
        :auto-position="false"
        class="ui-fixed ui-mx-auto"
      />
    </theme-provider>
  </div>
</template>

<script>
import { ThemeProvider } from '@estrategiahq/coruja-web-ui';
import { User } from '@/services/authService';
import CModal from '@/plugins/modal/component';
import CDialog from '@/plugins/dialog/component';
import CToast from '@/plugins/toast/component';
import CLoading from '@/plugins/loading/component';
import { events } from '@/constants';

export default {
  name: 'App',
  components: {
    ThemeProvider,
    CLoading,
    CModal,
    CDialog,
    CToast,
  },
  async created () {
    this.$subscribe(events.LOADING_START, () => this.$loading.show());
    this.$subscribe([events.LOADING_STOP, events.REQUEST_ERROR], () => this.$loading.hide());

    this.loggedUser = await this.$s.auth.getLoggedUser();
  },
  destroyed () {
    this.$unsubscribe(events.LOADING_START);
    this.$unsubscribe(events.LOADING_STOP);
    this.$unsubscribe(events.REQUEST_ERROR);
  },
  errorCaptured (e) {
    let message = `Houve um erro: ${e}`;
    if (e.isApiError) message = `${e.message} (${e.tag})`;

    this.$toast.show({
      type: 'error',
      text: message,
      timeout: 0,
    });

    return !e.isApiError; // Retornar false faz o erro não ser propagado
  },
  data () {
    return {
      loggedUser: new User(),
    };
  },
};
</script>

<style>
/* TODO: Remover esse import e repassar para o main.js conforme a conclusao da task: https://estrategia.atlassian.net/browse/DOD-91 */
@import "~@estrategiahq/ds-icons/dist/DesignSystemIcons.css";
#toast {
  top: 0;
  width: 100%;
}

@media (min-width: 767px) {
  #toast {
    bottom: 1rem;
    left: 0;
    right: 0;
    margin-top: auto;
    width: 544px !important;
  }
}
</style>
