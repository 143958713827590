export class PermissionService {
  constructor (httpClient) {
    this.$http = httpClient;
  }

  getAll () {
    return this.$http.get('/permissions');
  }

  get (id) {
    return this.$http.get(`/permissions/${id}`);
  }

  create (data) {
    return this.$http.post('/permissions', data);
  }

  update (id, data) {
    return this.$http.put(`/permissions/${id}`, data);
  }

  remove (id) {
    return this.$http.delete(`/permissions/${id}`);
  }
}
