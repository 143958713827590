import Vue from 'vue';
const _bus = new Vue();

export const bus = {
  publish: (evt, ...args) => _bus.$emit(evt, ...args),
  unsubscribe: (evt, callback) => _bus.$off(evt, callback),
  subscribe: (evt, callback) => {
    if (typeof evt === 'string') {
      _bus.$on(evt, callback);
    }
    if (Array.isArray(evt)) {
      for (const e of evt) {
        _bus.$on(e, callback);
      }
    }
  },
};

export const BusPlugin = {
  install (Vue) {
    Vue.prototype.$publish = bus.publish;
    Vue.prototype.$subscribe = bus.subscribe;
    Vue.prototype.$unsubscribe = bus.unsubscribe;
  },
};
