export class GroupService {
  constructor (httpClient) {
    this.$http = httpClient;
  }

  getAll () {
    return this.$http.get('/groups');
  }

  get (id) {
    return this.$http.get(`/groups/${id}`);
  }

  remove (id) {
    return this.$http.delete(`/groups/${id}`);
  }

  create (group) {
    return this.$http.post('/groups', group);
  }

  updatePermissions (id, permissions) {
    return this.$http.put(`/groups/${id}/permissions`, { permissions });
  }

  addUserToGroup (userId, groupId) {
    return this.$http.post(`/user/${userId}/groups/${groupId}`);
  }

  removeUserFromGroup (userId, groupId) {
    return this.$http.delete(`/user/${userId}/groups/${groupId}`);
  }
}
