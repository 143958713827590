import Axios from 'axios';

export class UserService {
  constructor (httpClient) {
    this.$http = httpClient;
  }

  async search (params = {}) {
    if (this.ongoingSearch) {
      this.ongoingSearch.cancel();
      this.ongoingSearch = null;

      // aguarda pra dar tempo do loading da request cancelada sumir,
      // e aparecer o loading do próximo get que será feito.
      await new Promise(resolve => setTimeout(resolve, 50));
    }

    this.ongoingSearch = Axios.CancelToken.source();

    const queryParams = {
      per_page: params.perPage,
      page: params.currentPage,
      order_by: params.orderBy,
      sort: params.sort.toLowerCase(),
      query: params.term,
      groups: params.groups || [],
    };

    const resp = await this.$http.get('/user', {
      params: queryParams,
      cancelToken: this.ongoingSearch.token,
    });
    const { data, meta } = resp.data;

    this.ongoingSearch = null;

    return {
      users: data,
      totalPages: meta.pagination.total_pages,
    };
  }

  getById (id) {
    return this.$http.get(`/user/${id}?embedded_fields=address,socialnetworks,groups,comment`);
  }

  update (user) {
    return this.$http.put(`/user/${user.id}`, user);
  }

  inviteUser (data) {
    return this.$http.post('/user/invite-manager', data);
  }

  getPaymentByUserId (userId) {
    return this.$http.get(`/user/${userId}/payment-info`);
  }

  updatePayment (payment) {
    return this.$http.put(`/user/${payment.userId}/payment-info`, payment);
  }

  loginAsOtherUser (userID) {
    return this.$http.post('/auth/login-as-other-user', { id: userID }, { withCredentials: true });
  }
}
