import { TOKEN } from '@/constants';

export class AuthService {
  constructor (authLib) {
    this._authLib = authLib;
  }

  /**
   * @returns {Promise<User>}
   */
  async getLoggedUser () {
    const token = localStorage.getItem(TOKEN);
    return this._authLib.getLoggedUser(token);
  }
}

export { User } from '@estrategiahq/frontend-libs/user-manager';
