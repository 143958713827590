<template>
  <div
    v-if="!!dialogData"
    id="modal-component-overlay"
    class="ui-fixed ui-top-0 ui-left-0 ui-z-50 ui-flex ui-items-center ui-justify-center ui-w-full ui-h-full"
    :style="`background: rgba(0, 0, 0, ${dialogData.overlayOpacity})`"
  >
    <div class="c-container ui-h-full">
      <div class="ui-h-full ui-w-full">
        <div class="c-row c-center ui-h-full">
          <div class="c-col-full sm:c-col-3 md:c-col-6 lg:c-col-4">
            <!-- Content -->
            <div class="c-bg-white ui-p-8">
              <!-- Título -->
              <div class="c-text-h3 c-text-gray ui-mb-2">
                {{ dialogData.title }}
              </div>

              <!-- Mensagem -->
              <div
                class="c-text-b2 c-text-gray ui-mb-6 ui-overflow-hidden"
                v-html="dialogData.message"
              />

              <div class="c-row c-horizontal-end">
                <div class="c-col-1/2">
                  <c-button
                    full
                    outline
                    data-cy="cancelarButton"
                    @click="onCancelDialog()"
                  >
                    {{ cancelButtonName }}
                  </c-button>
                </div>

                <div class="c-col-1/2">
                  <c-button
                    full
                    data-cy="confirmButton"
                    type="submit"
                    @click="onConfirmDialog()"
                  >
                    {{ confirmButtonName }}
                  </c-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { CButton } from '@estrategiahq/coruja-web-ui';

export default {
  name: 'CDialog',
  components: {
    CButton,
  },
  data: () => ({
    dialogData: null,
  }),
  mounted() {
    this.$dialog._onShow(data => {
      document.getElementById('app').classList.add('ui-overflow-hidden');
      this.dialogData = data;
    });
    this.$dialog._onHide(() => {
      document.getElementById('app').classList.remove('ui-overflow-hidden');
      this.dialogData = null;
    });
  },
  computed: {
    confirmButtonName() {
      return this.dialogData.confirmButtonName || 'Confirmar';
    },
    cancelButtonName() {
      return this.dialogData.cancelButtonName || 'Cancelar';
    },
  },
  methods: {
    onCancelDialog() {
      this.$dialog.onResolve(false);
      this.$dialog.hide();
    },
    onConfirmDialog() {
      this.$dialog.onResolve(true);
      this.$dialog.hide();
    },
  },
};
</script>
