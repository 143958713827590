export const ModalPlugin = {
  install: (Vue) => {
    Vue.prototype.$modal = {
      /**
       *
       * @param { Object } data
       * @param { object } data.component
       * @param { number } [data.overlayOpacity]
       * @param { object } [data.props]
       */
      show: function (data) {
        if (typeof data.component !== 'object' || typeof data.component.render !== 'function') {
          throw new Error('"component" must be a valid component');
        }

        if (data.overlayOpacity == null) {
          data.overlayOpacity = 0.25;
        }

        if (data.props && typeof data.props !== 'object') {
          throw new Error('"props" must be an object');
        }

        this.showCallback(data);
      },
      hide: function () { this.hideCallback() },
      _onShow: function (callback) { this.showCallback = callback },
      _onHide: function (callback) { this.hideCallback = callback },
    };
  },
};
