<template>
  <div
    v-if="isOpen && type"
    class="ui-flex ui-justify-center ui-w-full sm:ui-rounded ui-z-50 ui-transition ui-toast-container ui-ease-in-out ui-duration-300"
    :class="[styles.classes, autoPositionStyle]"
  >
    <div class="ui-relative ui-w-full ui-h-full ui-flex">
      <div class="ui-flex xs:ui-pr-2 xs:ui-pl-4 sm:ui-pr-4 sm:ui-pl-6 sm:ui-px-6 ui-h-full ui-items-center">
        <icon
          :name="styles.iconLeft"
          filled
          width="22"
          color="white"
        />
      </div>
      <div
        id="text-toast"
        class="ui-flex ui-mr-12 ui-text-xs ui-font-semibold xs:ui-flex-1 sm:ui-w-full ui-items-center c-text-white ui-overflow-hidden"
      >
        {{ text }}
      </div>
      <div class="ui-absolute ui-m-auto ui-right-0 ui-mr-4 sm:mr-6 ui-h-full ui-flex ui-justify-center">
        <button @click="onClose">
          <icon
            :name="styles.iconRight"
            width="22"
            color="white"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from '@estrategiahq/coruja-web-ui';

const types = {
  warning: {
    classes: 'c-bg-orange-300',
    iconLeft: 'Error',
    iconRight: 'Close',
  },
  error: {
    classes: 'c-bg-red-300',
    iconLeft: 'Error',
    iconRight: 'Close',
  },
  success: {
    classes: 'c-bg-green-400',
    iconLeft: 'Check',
    iconRight: 'Close',
  },
};
export default {
  name: 'CToast',
  components: { Icon },
  data: () => {
    return {
      isOpen: false,
      text: '',
      type: '',
      handler: () => {},
    };
  },
  props: {
    autoPosition: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    styles () {
      if (!types[this.type]) return types.warning;
      return types[this.type];
    },
    autoPositionStyle () {
      let style = '';
      if (this.autoPosition) style = 'ui-absolute toast-auto-position';
      return style;
    },
  },
  mounted () {
    this.$toast._onShow(this.onShow);
    this.$toast._onHide(this.onClose);
    this.$router.beforeEach((to, from, next) => {
      this.$toast.hide();
      next();
    });
  },
  methods: {
    onShow ({ type, text, timeout = 5000 }) {
      if (!Object.keys(types).includes(type)) {
        throw new Error('"type" is not valid');
      }

      if (typeof text !== 'string') {
        throw new Error('"text" argument must be a string');
      }

      this.type = type;
      this.text = text;
      this.isOpen = true;
      if (timeout !== 0) {
        setTimeout(this.onClose, timeout);
      }
    },
    onClose () {
      if (this.isOpen) {
        this.type = null;
        this.text = null;
        this.handler = null;
        this.isOpen = false;
      }
    },
  },
};
</script>

<style scoped>
.ui-toast-container {
  height: 4.5rem;
}

@media (min-width: 768px) {
  .ui-toast-container {
    height: 4.5rem;
    width: 37rem;
  }
}

.toast-auto-position {
  top: 1rem;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
</style>
